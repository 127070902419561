<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技与东北证券达成战略合作
              </p>
              <div class="fst-italic mb-2">2022年5月12日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >东北证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/08/战略合作封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    东北证券2022年第一届“金盏杯”私募实盘大赛，将于5月16日盛大开启。值此之际，非凸科技作为技术支持战略合作单位，联合东北证券，打造覆盖机构全生命周期的服务生态圈，为投资者带来全新的算法交易体验。
                  </p>
                  <p>
                    此次与东北证券达成战略合作关系，是非凸科技赋能券商做好机构服务的又一重要举措，有助于加速推进交易环节算法与执行能力的建设与创新。与东北证券共同创新，形成针对性的解决方案，联合开发实施落地。
                  </p>
                  <p>
                    双方将在智能算法服务、市场品牌推广等方面通力协作，共同围绕私募客户全生命周期业务需求而努力。双方一致认为，在新的机遇和挑战面前，双方将协同共进，共商共赢，充分运用各自资源和技术优势，在服务客户、完善产品体系等方面探索合作新模式，拓宽合作新渠道，从而促进双方共同成长。
                  </p>
                  <p >
                    本次合作背后，正是东北证券对客户机构化趋势的敏锐洞察，对算法交易及执行系统化需求的深度理解，以及对信息技术领域加大投入的高度重视。
                  </p>
                  <p >
                    非凸科技自成立以来，已持续为近百家券商、量化私募等大型金融机构提供算法交易及执行专业化解决方案。公司基于 Rust 生态体系，运用机器学习、深度学习等新兴技术，构建交易执行全场景、全周期、全覆盖的智能化升级服务与应用。
                  </p>
                  <p >
                    此次战略合作的达成，将是一个新的开始。在未来，双方将加强更多的创新业务合作，向着更加紧密，更加适应行业发展趋势的方向共赢共生。
                  </p>
                  <h4 class="mb-4">欢迎申请试用</h4>
                  <p >
                    可直接咨询东北证券营业部申请试用，或联系非凸科技商务部咨询定制化算法合作。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/08/实盘大赛.jpg"
                      alt="东北证券"
                    />
                  </figure>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
