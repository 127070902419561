<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                新伙伴！非凸算法即将上线海通证券
              </p>
              <div class="fst-italic mb-2">2022年12月16日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >海通证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/26/01-海通.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技与海通证券达成总对总合作，发挥各自优势，共建量化生态。双方将在算法策略、技术创新、产品服务、品牌推广等方面，围绕机构客户全生命周期业务需求，通力协作，携手共赢。此次合作，也是对非凸实力的再一次肯定。
                  </p>
                   <p>
                    非凸算法在传统算法交易的基础上，融入AI最新研究成果，采用Rust技术架构，利用高频数据生成特征数据，通过机器学习模型训练，对价格趋势进行预测，并根据预测结果调整执行计划。凭借先进的策略、稳定的系统和完善的服务，以及热忱的技术信仰、持续的策略迭代，非凸最新算法自上线以来，绩效基本领先市场，获得券商及管理人的高度认可。
                  </p>
                  <p>
                    优秀的业绩源于出色的团队，金融素养和工程实力过硬。非凸科技量化策略及工程师团队均毕业于海内外知名院校，获得ACM World Final等国际竞赛奖项，拥有强大的编程技能和敏锐的量化思维；除此之外，团队经验丰富，技术实力雄厚。非凸科技希望汇聚一群专注、聪明、勇敢的人，在追求极致中突破自我，创造个人与团队成长的超额价值。
                  </p>
                  <p>
                    作为证券行业成立最早、综合实力最强的证券公司之一，海通证券积极探索金融科技应用，主动拥抱数字化转型新趋势，不断推动人工智能、大数据等新技术在金融领域的深入应用，努力为行业高质量发展探索新路、积累经验、提供样板。除此之外，海通证券引领行业多项科技创新，已在核心技术攻关、信息技术应用创新、金融科技应用、前瞻性课题研究等领域，取得了多项奖励和荣誉。
                  </p>
                  <p>
                    双方合作的背后，正是海通证券敏锐洞察客户机构化趋势，深度理解行业对算法交易的迫切需求。与此同时，非凸科技将充分发挥作为金融科技厂商的技术优势，推动相关创新技术的落地，助力海通证券实现业务增长、管理提效。
                  </p>
                  <p>
                    除海通证券外，还有中金财富、东北证券、国投证券、国联证券等多家头部券商，将在近期陆续上线非凸算法。非凸科技作为一家年轻的公司，展业姿态更加积极主动。算法上线时，不仅提供总对总路演交流，还调配人员触达各地营业部配合展业，助力券商机构解决专业问题，减少展业阻滞。
                  </p>
                  <p>
                    深耕细作，奋楫笃行。非凸科技将携手生态伙伴，展开多元合作，实现前沿技术新突破，赋能量化交易新未来。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News26",
};
</script>

<style></style>
